<template>
  <div>
    <div class="district-content-backview">
      <el-row>
        <el-col :span="6" v-for="(itemData, index) in districtList" v-bind:key="index">
          <div class="district-itemview">
            <div :id="`auth_${index}`" style="width: 200px; height: 200px"></div>
            <div class="district-desc">
              总数:{{ itemData.allNumber }}所，今年:{{ itemData.thisYear }}所,
              新增:{{ itemData.addNumber }}所
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  created () {
    this.$nextTick(() => {
      this.initCharts()
    })
  },
  methods: {
    initCharts () {
      for (var i = 0; i < this.districtList.length; i++) {
        var itemData = this.districtList[i]
        var myChart = echarts.init(document.getElementById('auth_' + i)) // 循环创建每个饼图的配置项和数据
        var option = {
          color: ['#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'],
          title: {
            text: '{b|' + itemData.districtName + '}',
            subtext: '{a|' + '总数:' + itemData.allNumber + '所}',
            subtextStyle: {
              rich: {
                a: {
                  fontSize: '16',
                  color: '#000'
                }
              }
            },
            textStyle: {
              rich: {
                b: {
                  fontSize: '14'
                }
              }
            },
            left: 'center',
            top: '78px'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)'
          },
          series: [
            {
              name: this.districtList[i].name,
              type: 'pie',
              label: {
                show: false
              },
              radius: ['66%', '82%'],
              // center: ['50%', '60%'],
              data: this.districtList[i].data,
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }

        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option)
      }
    }
  },
  data () {
    return {
      districtList: [
        {
          districtName: '测试赛区一',
          allNumber: '100',
          thisYear: '50',
          addNumber: '50',
          data: [
            { value: 335, name: '总数' },
            { value: 310, name: '新增' },
            { value: 234, name: '今年' }
          ]
        },
        {
          districtName: '测试赛区二',
          allNumber: '100',
          thisYear: '50',
          addNumber: '50',
          data: [
            { value: 335, name: '总数' },
            { value: 310, name: '今年' },
            { value: 234, name: '新增' }
          ]
        },
        {
          districtName: '测试赛区三',
          allNumber: '100',
          thisYear: '50',
          addNumber: '50',
          data: [
            { value: 335, name: '总数' },
            { value: 310, name: '今年' },
            { value: 234, name: '新增' }
          ]
        },
        {
          districtName: '测试赛区四',
          allNumber: '100',
          thisYear: '50',
          addNumber: '50',
          data: [
            { value: 335, name: '总数' },
            { value: 310, name: '今年' },
            { value: 234, name: '新增' }
          ]
        }
      ]
    }
  }
}
</script>

  <style scoped>
.district-content-backview {
  width: 90%;
  margin-left: 5%;
}
.district-itemview {
  width: 200px;
  margin: 0 auto 40px;
  display: flex;
  flex-direction: column;
}
.district-desc {
  width: 100%;
  font-size: 12px;
  color: rgb(49, 49, 49);
}
</style>
